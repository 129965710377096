import "./App.css";
import React, { useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const form = useRef();
  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.PUBLIC_URL + "/scripts/main.js";

    const script2 = document.createElement("script");
    script2.src = process.env.PUBLIC_URL + "/scripts/aos.js";

    const script3 = document.createElement("script");
    script3.src = process.env.PUBLIC_URL + "/scripts/aos.js";

    script.async = true;
    document.body.appendChild(script);

    script2.async = true;
    document.body.appendChild(script2);

    script3.async = true;
    document.body.appendChild(script3);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
      document.body.removeChild(script3);
    };
  }, []);
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_ntli8e6",
        "template_l0b7pdh",
        form.current,
        "user_RXDIE3RQewUckRcjaSx5L"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message envoyé avec succès", {
            position: toast.POSITION.TOP_CENTER,
          });
          form.current.reset();
        },
        (error) => {
          toast.error("Un problème est survenu", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          console.log(error.text);
        }
      );
  };
  return (
    <div className="App">
      <ToastContainer autoClose={3000} />
      <header className="d-print-none">
        <div className="container text-center text-lg-left">
          <div className="py-3 clearfix">
            <h1 className="site-title mb-0">Bertini Meyong</h1>
            <div className="site-nav">
              <nav role="navigation">
                <ul className="nav justify-content-center">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://www.linkedin.com/in/bertini-meyong-3228a214b"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                      <span className="menu-title sr-only">LinkedIn</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://github.com/Yakusa26"
                      title="Github"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-github"></i>
                      <span className="menu-title sr-only">Github</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="page-content">
        <div className="container">
          <div className="cover shadow-lg bg-white">
            <div className="cover-bg p-3 p-lg-4 text-white">
              <div className="row">
                <div className="col-lg-4 col-md-5">
                  <div className="avatar hover-effect bg-white shadow-sm p-1">
                    <img
                      src="images/avatar.jpeg"
                      width="200"
                      height="200"
                      alt="Avatar"
                    />
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 text-center text-md-start">
                  <h2
                    className="h1 mt-2"
                    data-aos="fade-left"
                    data-aos-delay="0"
                  >
                    Bertini Meyong
                  </h2>
                  <p data-aos="fade-left" data-aos-delay="100">
                    Développeur Full Stack
                  </p>
                  <div
                    className="d-print-none"
                    data-aos="fade-left"
                    data-aos-delay="200"
                  >
                    <a
                      className="btn btn-light text-dark shadow-sm mt-1 me-1"
                      href={process.env.PUBLIC_URL + "/cv/BertiniMeyong_CV.pdf"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Télécharger le CV
                    </a>
                    <a
                      className="btn btn-success shadow-sm mt-1"
                      href="#contact"
                    >
                      Me joindre
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-section pt-4 px-3 px-lg-4 mt-1">
              <div className="row">
                <div className="col-md-6">
                  <h2 className="h3 mb-3">A propos de moi</h2>
                  <p>
                    Titulaire d'un Mastère 2 Développement obtenu à Campus
                    Academy Sud, je suis diplômé de l'Institut Universitaire de
                    Technologie Fotso-Victor de Bandjoun, spécialisé en
                    Conception, Développement Réseaux et Internet. À côté de
                    cette casquette technique, je porte aussi une casquette
                    managériale après un Bachelor en Management et Conseil en
                    Systèmes d'Information obtenu à l'Ecole Supérieure de Génie
                    Informatique de Paris
                  </p>
                </div>
                <div className="col-md-5 offset-md-1">
                  <div className="row mt-2">
                    <div className="col-sm-4">
                      <div className="pb-1">Âge</div>
                    </div>
                    <div className="col-sm-8">
                      <div className="pb-1 text-secondary">27</div>
                    </div>
                    <div className="col-sm-4">
                      <div className="pb-1">Email perso</div>
                    </div>
                    <div className="col-sm-8">
                      <div className="pb-1 text-secondary">
                        <a href="mailto:bertinim26@gmail.com">
                          bertinim26@gmail.com
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="pb-1">Email Pro</div>
                    </div>
                    <div className="col-sm-8">
                      <div className="pb-1 text-secondary">
                        <a href="mailto:bertini@flowfactory.fr">
                          bertini@flowfactory.fr
                        </a>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="pb-1">Téléphone</div>
                    </div>
                    <div className="col-sm-8">
                      <div className="pb-1 text-secondary">
                        <a href="tel:+33611439167">+33611439167</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="d-print-none" />
            <div className="skills-section px-3 px-lg-4">
              <h2 className="h3 mb-3">Compétences</h2>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-2">
                    <span>Nintex Automation On-Prem</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="100"
                        data-aos-anchor=".skills-section"
                        style={{ width: "75%" }}
                        aria-valuenow="75"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <span>ReactJS</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="200"
                        data-aos-anchor=".skills-section"
                        style={{ width: "65%" }}
                        aria-valuenow="65"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <span>React Native</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="300"
                        data-aos-anchor=".skills-section"
                        style={{ width: "60%" }}
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="mb-2">
                    <span>Node + Express</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="400"
                        data-aos-anchor=".skills-section"
                        style={{ width: "60%" }}
                        aria-valuenow="60"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <span>MongoDB</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="500"
                        data-aos-anchor=".skills-section"
                        style={{ width: "50%" }}
                        aria-valuenow="50"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <span>Power Platform</span>
                    <div className="progress my-1">
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        data-aos="zoom-in-right"
                        data-aos-delay="600"
                        data-aos-anchor=".skills-section"
                        style={{ width: "70%" }}
                        aria-valuenow="70"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="d-print-none" />
            <div className="work-experience-section px-3 px-lg-4">
              <h2 className="h3 mb-4">Expérience Professionnelle</h2>
              <div className="timeline">
                <div className="timeline-card timeline-card-primary card shadow-sm">
                  <div className="card-body">
                    <div className="h5 mb-1">
                      Développeur Full Stack{" "}
                      <span className="text-muted h6">chez Flow Factory</span>
                    </div>
                    <div className="text-muted text-small mb-2">
                      Février, 2018 - Présent
                    </div>
                    <div>
                      Analyse, conception, réalisation et audit des systèmes
                      d’information et des processus métiers via des
                      technologies comme Nintex Automation On-Prem, ReactJS,
                      Power Platform
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="d-print-none" />
            <div className="page-break"></div>
            <div className="education-section px-3 px-lg-4 pb-4">
              <h2 className="h3 mb-4">Formations</h2>
              <div className="timeline">
                <div className="timeline-card timeline-card-success card shadow-sm">
                  <div className="card-body">
                    <div className="h5 mb-1">
                      Expert en Ingénierie de l'Informatique{" "}
                      <span className="text-muted h6">à Campus Academy</span>
                    </div>
                    <div className="text-muted text-small mb-2">
                      Oct 2020 - Sept 2022
                    </div>
                    <div>
                      Formation au cours de laquelle on approfondit des notions
                      de Programmation Orientée Objet, d'algorithmique avancée,
                      de devéloppement mobile et de gestion projets entre autres
                    </div>
                  </div>
                </div>
                <div className="timeline-card timeline-card-success card shadow-sm">
                  <div className="card-body">
                    <div className="h5 mb-1">
                      Bachelor Management et Conseil en Système d'Information{" "}
                      <span className="text-muted h6">à l'ESGI Paris</span>
                    </div>
                    <div className="text-muted text-small mb-2">
                      Oct 2019 - Juil 2020
                    </div>
                    <div>
                      Formation au cours de laquelle j’ai acquis des compétences
                      en gestion des projets (planification, budgétisation, les
                      méthodes agiles, etc.)
                    </div>
                  </div>
                </div>
                <div className="timeline-card timeline-card-success card shadow-sm">
                  <div className="card-body">
                    <div className="h5 mb-1">
                      Licence de Technologie{" "}
                      <span className="text-muted h6">à l'IUT de Bandjoun</span>
                    </div>
                    <div className="text-muted text-small mb-2">
                      Sept 2016 - Juil 2017
                    </div>
                    <div>
                      Spécialisé dans l’analyse, la modélisation (Avec MERISE et
                      le langage UML 2.0) et la réalisation des applications
                      réseaux. Mémoire de fin d’étude soutenu avec une
                      appréciation « Excellente »
                    </div>
                  </div>
                </div>
                <div className="timeline-card timeline-card-success card shadow-sm">
                  <div className="card-body">
                    <div className="h5 mb-1">
                      Diplôme Supérieur d'Etudes Professionnelles{" "}
                      <span className="text-muted h6">
                        à l'Institut Supérieur Siantou
                      </span>
                    </div>
                    <div className="text-muted text-small mb-2">
                      Sept 2014 - Aout 2016
                    </div>
                    <div>
                      Formation au cours de laquelle j’ai acquis des compétences
                      solides en analyse, et modélisation (MERISE et UML) des
                      systèmes d’information et en Algorithmique
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="d-print-none" />
            <div className="contant-section px-3 px-lg-4 pb-4" id="contact">
              <h2 className="h3 text mb-3">Contact</h2>
              <div className="row">
                <div className="col-md-7 d-print-none">
                  <div className="my-2">
                    <form ref={form} onSubmit={sendEmail}>
                      <div className="row">
                        <div className="col-6">
                          <input
                            className="form-control"
                            type="text"
                            id="name"
                            name="from_name"
                            placeholder="Votre nom"
                            required
                          />
                        </div>
                        <div className="col-6">
                          <input
                            className="form-control"
                            type="email"
                            id="email"
                            name="from_email"
                            placeholder="Votre email"
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group my-2">
                        <textarea
                          className="form-control"
                          style={{ resize: "none" }}
                          id="message"
                          name="message"
                          rows="4"
                          placeholder="Votre message"
                          required
                        ></textarea>
                      </div>
                      <button className="btn btn-primary mt-2" type="submit">
                        Envoyer
                      </button>
                    </form>
                  </div>
                </div>
                <div className="col">
                  <div className="mt-2">
                    <h3 className="h6">Téléphone</h3>
                    <div className="pb-2 text-secondary">+33 6 11 43 91 67</div>
                    <h3 className="h6">Email</h3>
                    <div className="pb-2 text-secondary">
                      bertinim26@gmail.com
                    </div>
                  </div>
                </div>
                <div className="col d-none d-print-block">
                  <div className="mt-2">
                    <div>
                      <div className="mb-2">
                        <div className="text-dark">
                          <i className="fab fa-linkedin mr-1"></i>
                          <span>
                            https://www.linkedin.com/in/bertini-meyong-3228a214b
                          </span>
                        </div>
                      </div>
                      <div className="mb-2">
                        <div className="text-dark">
                          <i className="fab fa-github mr-1"></i>
                          <span>https://github.com/Yakusa26</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="pt-4 pb-4 text-muted text-center d-print-none">
        <div className="container">
          <div className="my-3">
            <div className="h4">Bertini Meyong</div>
            <div className="footer-nav">
              <nav role="navigation">
                <ul className="nav justify-content-center">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://www.linkedin.com/in/bertini-meyong-3228a214b"
                      title="LinkedIn"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-linkedin"></i>
                      <span className="menu-title sr-only">LinkedIn</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="https://github.com/Yakusa26"
                      title="Github"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fab fa-github"></i>
                      <span className="menu-title sr-only">Github</span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div className="text-small">
            <div className="mb-1">
              &copy; Bertini Meyong - CV. Tous droits reservés.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
